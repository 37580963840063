import React from 'react'

function Inprogress() {
    return (
        <div className='admin-dashboard'>
          <div className="table-wrapper">
            <table>
              <thead>
                <th>Name</th>
                <th>Req_id</th>
                <th>Req_Name</th>
                <th>Req_Date</th>
                <th>Location</th>
                <th>Department</th>
              </thead>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              <tr>
                <td data-cell="Name">Wanofi Israel</td>
                <td data-cell="Req_ID">124321</td>
                <td data-cell="Req_Name">Computer Problem</td>
                <td data-cell="Req_Date">8/ 12 /24</td>
                <td data-cell="Location">Lideta</td>
                <td data-cell="Department">ICT</td>
              </tr>
              
              
              
            </table>
          </div>
        </div>
      )
}

export default Inprogress
