import React from 'react'

function LoadingBtn() {
  return (
    <button className='loading-btn' disabled>
      <div className="spinner">
        <div className="spinner-top">

        </div>
      </div>
    </button>
  )
}

export default LoadingBtn
